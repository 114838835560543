import * as React from 'react'
import { Text, View, TextStyle, StyleProp } from 'react-native'

interface PProps {
  style?: StyleProp<TextStyle>
  fontSize?: number
  marginBottom?: number
}

export const P: React.SFC<PProps> = ({
  style,
  children,
  fontSize,
  marginBottom,
}) => {
  return (
    <View>
      <Text
        style={[
          {
            fontFamily: 'Comfortaa, Open Sans, Roboto, Helvetica, sans-serif',
            fontSize,
            lineHeight: fontSize! * 1.42,
            marginBottom: marginBottom ? marginBottom : fontSize! * 1.5,
          },
          style,
        ]}
      >
        {children}
      </Text>
    </View>
  )
}

P.defaultProps = {
  fontSize: 14,
}
