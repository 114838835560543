import React from 'react'

import '../theme/thanks.sass'
import { Trans } from '@lingui/react'
import { P } from '@src/components/atoms/P'
import { graphql } from 'gatsby'

const ThanksPage = (_: any) => (
  <div className="thanksPage">
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <Trans>
        <P fontSize={18}>Thank you for contact us!!</P>
        <P fontSize={18}>
          We are working hard, but sometime we can’t response fast.
        </P>
        <P fontSize={18}>We will response as soon as possible.</P>
      </Trans>
    </div>
  </div>
)

export default ThanksPage

export const pageQuery = graphql`
  query ThanksPageQuery($language: String!) {
    ...FooterFragment
  }
`
